<!--
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-09-16 15:30:38
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-20 14:23:39
-->
<template>
  <div class="content">
    <div v-if="ShowRouterTabs">
      <RouterTabs v-model="activeName" :tabArr="tabs" :paramsData="params" />
    </div>
    <router-view />
  </div>
</template>

<script>
import RouterTabs from "@/base/components/Default/RouterTabs.vue";
export default {
  data() {
    return {
      ShowRouterTabs: true, // 控制tab栏显示隐藏
      listArr: [
        "EditProject",
        "MessageList",
        "DonationStats",
      ], // 带tab栏的页面路由name
      params: {}, // 路由传输数据
      activeName: "donationProject", //当前选中tab
      // tab配置
      tabs: [ 
        { label: "项目详情", name: "EditProject", params: true },
        { label: "留言列表", name: "MessageList", params: true },
        { label: "数据统计", name: "DonationStats", params: true },

      ],
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    this.ShowRouterTabs = this.listArr.includes(this.routeName) ? true : false;
    this.activeName = this.$route.name;
  },
  watch: {
    routeName(val) {
      this.ShowRouterTabs = this.listArr.includes(val) ? true : false;
      if (this.listArr.includes(val)) {
        this.params = this.$route.params;
        this.activeName = val;
      }
    },
  },
  components: { RouterTabs },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0;
}
</style>